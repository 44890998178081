import React, { useState } from "react";
import FilterTable from "../../common/FilterTable";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TextField } from "@material-ui/core";
import { AppButtonComponent } from "@unity/components";

export default function GoodsReceivedForm(props) {

    const [rows, setRows] = useState([])

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: props.bg,
            color: theme.palette.common.white,
            textAlign: "left",
        },
        body: {
            fontSize: 14,
            textAlign: "left",
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    });

    const classes = useStyles();

    const purchasing_columns = [
        { name: "name", label: "Product Name" },
        { name: "reference", label: "Product Ref" },
        { name: "supplier_name", label: "Supplier Name" },
        { name: "quantity", label: "Qty Requested" },
        {
            name: "",
            label: "Action",
            options: {
                customBodyRenderLite: (dataIndex) => {

                    if (props.data[dataIndex].received) {
                        return <p style={{ margin: 0, fontWeight: 'bold', color: 'green' }}>Received</p>
                    } else if (props.type === "edit") {
                        return  <AppButtonComponent
                                    onClick={() => props.handleAddDelivery(dataIndex)}
                                    text="new delivery"
                                    btnIcon="add"
                                    style={{
                                        background: props.bg,
                                        color: "white",
                                    }}
                                />
                    } else {
                        return ""
                    }
                },
            },
        },
    ];

    const handleRows = (data) => {
        var el = []
        data.map((item) => el.push(item.dataIndex));
        setRows(el)
    }

    const options = {
        filterType: "dropdown",
        responsive: "stacked",
        print: true,
        rowsPerPage: 10,
        page: 0,
        selectableRows: "none",
        expandableRows: true,
        onRowExpansionChange: (current, all, rows) => handleRows(current),
        rowsExpanded: [rows],
        expandableRowsOnClick: true,
        renderExpandableRow: (rowData, rowMeta) => {
            return (
                <React.Fragment>
                    <tr>
                        <td colSpan={7}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">Line Item</StyledTableCell>
                                            <StyledTableCell align="left">Delivery Number</StyledTableCell>
                                            <StyledTableCell align="left">Comments</StyledTableCell>
                                            <StyledTableCell align="left">Quantity</StyledTableCell>
                                            <StyledTableCell align="left">Date</StyledTableCell>
                                            <StyledTableCell align="left">Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.data[rowMeta.rowIndex].deliveries.map((item, key2) => (
                                            <StyledTableRow key={item.id}>
                                                <StyledTableCell component="th" scope="row">
                                                    {item.item_line_id}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {item.id ?
                                                        item.delivery_number
                                                        :
                                                        <TextField
                                                            autoFocus={
                                                                props.lineFocus === "delivery_number_".concat(rowMeta.rowIndex).concat('_').concat(key2) ? true : false
                                                            }
                                                            name="delivery_number"
                                                            type="text"
                                                            value={item.delivery_number}
                                                            fullWidth onChange={(e) => props.handleChange(
                                                                                            e.target.name,
                                                                                            e.target.value,
                                                                                            rowMeta.rowIndex,
                                                                                            key2
                                                                                        )}
                                                        />
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {item.id ?
                                                        item.comments
                                                        :
                                                        <TextField
                                                            autoFocus={
                                                                props.lineFocus === "comments_".concat(rowMeta.rowIndex).concat('_').concat(key2) ? true : false
                                                            }
                                                            name="comments"
                                                            type="text"
                                                            value={item.comments}
                                                            fullWidth
                                                            onChange={(e) => props.handleChange(
                                                                                    e.target.name,
                                                                                    e.target.value,
                                                                                    rowMeta.rowIndex,
                                                                                    key2
                                                                                )}
                                                        />
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {item.id ?
                                                        item.quantity
                                                        :
                                                        <TextField
                                                            autoFocus={
                                                                props.lineFocus === "quantity_".concat(rowMeta.rowIndex).concat('_').concat(key2) ? true : false
                                                            }
                                                            name="quantity"
                                                            type="number"
                                                            value={item.quantity}
                                                            fullWidth
                                                            onChange={(e) => props.handleChange(
                                                                                    e.target.name,
                                                                                    e.target.value,
                                                                                    rowMeta.rowIndex,
                                                                                    key2
                                                                                )}
                                                        />
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {item.id ?
                                                        item.date
                                                        :
                                                        <TextField
                                                            autoFocus={
                                                                props.lineFocus === "date_".concat(rowMeta.rowIndex).concat('_').concat(key2) ? true : false
                                                            }
                                                            name="date"
                                                            type="date"
                                                            value={item.date}
                                                            fullWidth
                                                            onChange={(e) => props.handleChange(
                                                                                    e.target.name,
                                                                                    e.target.value,
                                                                                    rowMeta.rowIndex,
                                                                                    key2
                                                                                )}
                                                        />
                                                    }
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    {(props.type === "edit") && item.id &&
                                                        <AppButtonComponent
                                                          onClick={() => props.handleDeleteDelivery(rowMeta.rowIndex, key2)}
                                                          text="delete"
                                                          btnIcon="delete"
                                                          style={{
                                                            background: "red",
                                                            color: "white",
                                                          }}
                                                        />
                                                    }
                                                    {(props.type === "edit") && !item.id &&
                                                        <AppButtonComponent
                                                          onClick={() => props.handleSaveDelivery(rowMeta.rowIndex, key2)}
                                                          text="save"
                                                          btnIcon="save"
                                                          style={{
                                                            background: "green",
                                                            color: "white",
                                                          }}
                                                        />
                                                    }
                                                </StyledTableCell>

                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </td>
                    </tr>
                </React.Fragment>
            );
        },
    };
    return (
        <>
            <div className="block" style={{ marginBottom: 15 }}>
                <div className="container-fluid">
                    <div className="form-row">
                        <div className="form-group col-lg-12">
                            <h3>Goods Received </h3>
                        </div>
                    </div>
                </div>
            </div>


            <FilterTable columns={purchasing_columns} data={props.data} options={options} />

        </>
    );
}
