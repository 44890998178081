import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import AppButton from "../common/AppButton";
import Loading from "../common/Loading";
import { AppHeaderComponent, PurchasingApi } from "@unity/components";
import FilterTable from "../common/FilterTable";
import ComponentTabs from "../common/Tabs";
import { CanUpdate } from "../services/Permissions";

export default function GoodsReceived({ route, context }) {

  const [data, setData] = useState(false);

  const edit = CanUpdate("goods-received");

  const purchase_requests_columns = [
    { name: "reference", label: "PO Ref#" },
    { name: "costing_ref", label: "ABC Ref#" },
    { name: "supplier_name", label: "Supplier Name" },
    {
      name: "", label: "All Received", options: {
        customBodyRenderLite: (dataIndex) => {
          if (data[dataIndex].cancelled) {
            return <b style={{ color: 'red' }}>Cancelled</b>
          } else if (data[dataIndex].all_received) {
            return <b style={{ color: 'green' }}>Goods Received</b>
          } else {
            return <b style={{ color: 'blue' }}>Awaiting Goods</b>
          }
        }
      }
    },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {

          if (tabValue === 0) {
            return (
              <>
                <Link
                  to={{
                    pathname: `/goods-received/view/${data[dataIndex].id}`,
                  }}
                >
                  <AppButton
                    text="View"
                    btnIcon="visibility"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                    }}
                  />
                </Link>

                {edit && !data[dataIndex].cancelled && (
                  <Link
                    to={{
                      pathname: `/goods-received/edit/${data[dataIndex].id}`,
                    }}
                  >
                    <AppButton
                      text="edit"
                      btnIcon="edit"
                      style={{
                        background: context.theme.sidebar.background,
                        color: "white",
                        marginLeft: 10,
                      }}
                    />
                  </Link>
                )}

              </>
            );
          } else {
            <div>?</div>
          }
        },
      },
    },
  ];


  const getPurchaseIndex = async () => {
    const res = await PurchasingApi.getPurchaseOrders();
    setData(res.data)
    console.log("DATA",res.data);
  };

  useEffect(() => {
    getPurchaseIndex();
  }, []);

  var select = "multiple";

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    rowsPerPage: 10,
    page: 0,
    selectableRows: select,
    selectableRows: "none",
  };

  const [tabValue, setTabValue] = useState(0)

  const tabs = [
    { icon: "check", label: "Purchase Orders" },
    // { icon: "settings", label: "Module Settings" },
  ];

  const handleChange = async (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <AppHeaderComponent context={context} theme={context.theme} name="goods-received" />
      <ComponentTabs
        tabs={tabs}
        value={tabValue}
        setValue={setTabValue}
        handleChange={handleChange}
      />
      <div style={{ paddingTop: 166 }}>
        {(tabValue === 0) &&
          <>
            {data ?
              <FilterTable
                columns={purchase_requests_columns}
                data={data}
                options={options}
              />
              :
              <Loading />
            }
          </>
        }

      </div>
    </>
  );
}
