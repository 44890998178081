import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import GoodsReceivedView from "./pages/view";
import GoodsReceived from "./pages/index";
import GoodsReceivedEdit from "./pages/edit";
export default function Root(props) {

  let context = { ...props }

  let history = useHistory();

  return (
    <AuthContext.Provider
      value={{
        ...props
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {props.auth.access['goods-received']["A"] && props.auth.access['goods-received']["R"] &&
                      <Route path="/goods-received/index" exact component={(route) => <GoodsReceived route={route} history={history} context={context} />} />}

                    {props.auth.access['goods-received']["A"] && props.auth.access['goods-received']["R"] &&
                      <Route
                        path="/goods-received/view/:id"
                        exact
                        component={(route) => <GoodsReceivedView route={route} history={history} context={context} />}
                      />}
                    {props.auth.access['goods-received']["A"] && props.auth.access['goods-received']["U"] &&

                      <Route
                        path="/goods-received/edit/:id"
                        exact
                        component={(route) => <GoodsReceivedEdit route={route} context={context} history={history} />}
                      />
                    }

                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}