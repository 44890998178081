import React, { useEffect, useState } from "react";
import Loading from "../common/Loading";
import { AppHeaderComponent, PurchasingApi } from "@unity/components";
import GoodsReceivedForm from "../modules/requests/GoodsReceivedForm";

export default function GoodsReceivedView({ context, route }) {
  const [data, setData] = useState(false)

  const getProducts = async () => {
    const res = await PurchasingApi.getPurchaseOrderProducts(route.match.params.id);
    console.log("received",res.data);
    setData(res.data);
  };

  const handleUpload = (e) => { };


  const handleChange = (name, value) => { };


  useEffect(() => {
    getProducts();
  }, []);


  if (data) {
    return (
      <>
        <AppHeaderComponent context={context} theme={context.theme} name="goods-received" />

        <div style={{ paddingTop: 80, paddingBottom: 40 }}>
          <GoodsReceivedForm
            data={data}
            type="view"
            handleChange={handleChange}
            handleUpload={handleUpload}
            bg={context.theme.sidebar.background}
          />
        </div>
      </>

    );
  } else {
    return <Loading />;
  }
}
