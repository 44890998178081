import React, { useEffect, useState } from "react";
import { AppHeaderComponent, PurchasingApi } from "@unity/components";
import GoodsReceivedForm from "../modules/requests/GoodsReceivedForm";
import Loading from "../common/Loading";

export default function GoodsReceivedEdit({ route, context }) {
  const [data, setData] = useState(false);
  const [lineFocus, setLineFocus] = useState(false);

  const getProducts = async () => {
    const res = await PurchasingApi.getPurchaseOrderProducts(route.match.params.id);
    setData(res.data);
  };

  const handleChange = (name, value, key, key2) => {
    const newData = [...data];
    newData[key]['deliveries'][key2][name] = value;
    setData(newData);
    setLineFocus([name, key, key2].join("_"));
  }

  const handleAddDelivery = (key) => {
    const newData = [...data];
    newData[key]['deliveries'].push({
      quantity: 1,
      date: ""
    });
    setData(newData)
    setLineFocus(false);
  }

  const handleSaveDelivery = async (key, key2) => {
    const newData = [...data];
    newData[key]['deliveries'][key2]['product_id'] = newData[key].id;
    newData[key]['deliveries'][key2]['purchase_order_id'] = newData[key].purchase_order_id;
    const res = await PurchasingApi.createDelivery(newData[key]['deliveries'][key2]);
    newData[key]['deliveries'][key2] = res.data;
    setData(newData);
    setLineFocus(false);
    await getProducts()
  }

  const handleDeleteDelivery = async (key, key2) => {
    const newData = [...data];
    await PurchasingApi.deleteDelivery(newData[key]['deliveries'][key2].id, { purchase_order_id: newData[key].purchase_order_id });
    newData[key]['deliveries'].splice(key2, 1)
    setData(newData);
    setLineFocus(false);
    await getProducts()
  }

  useEffect(() => {
    getProducts();
  }, []);

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          theme={context.theme}
          name="goods-received"
          subpage="edit"
        />

        <div style={{ paddingTop: 80, paddingBottom: 40 }}>
          <GoodsReceivedForm
            data={data}
            lineFocus={lineFocus}
            setData={setData}
            bg={context.theme.sidebar.background}
            handleChange={handleChange}
            handleAddDelivery={handleAddDelivery}
            handleSaveDelivery={handleSaveDelivery}
            handleDeleteDelivery={handleDeleteDelivery}
            type="edit"
          />
        </div>
      </>

    );
  } else {
    return <Loading />;
  }
}